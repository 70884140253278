import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Provide Safe Ride Solutions
                </h2>

                <p className="section__description">
                We understand that your journey begins the moment you step into one of our vehicles, and we strive to make it a comfortable and enjoyable one.
                What sets us apart is our commitment to exceptional customer service. 
                Our knowledgeable and friendly team is always ready to assist you in finding the ideal vehicle, answering your questions, and ensuring a smooth rental process from start to finish.
                We believe that personalized attention and attention to detail make all the difference in providing an outstanding car rental service.
                </p>

                <p className="section__description">
                When you choose our car rental service, you can expect competitive prices, transparent policies, and a hassle-free experience. 
                We value your time and understand the importance of reliability, which is why we maintain our vehicles to the highest standards and prioritize your safety and comfort.
                Whether you're a frequent traveler or renting a car for the first time, we are here to make your journey convenient and stress-free. 
                Trust us to be your reliable partner on the road, offering quality vehicles, exceptional service, and a commitment to your satisfaction.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+254 718472188</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <BecomeDriverSection />

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
